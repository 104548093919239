import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser, reset } from "../../store/users";

import { Table, Col, Row, Card, CardBody, CardTitle, Button } from "reactstrap";
//componente de paginação
import Pagination from "../../components/Pagination";
//services
import {
  getParticipants,
  getRoommatesRoom,
} from "../../utils/services/roommates";

import SweetAlert from "react-bootstrap-sweetalert";

import { isMobile } from "react-device-detect";

import { useNavigate } from "react-router-dom";

import { ParticipantContext } from "../../providers/participant";
import { MatchContext } from "../../providers/match";

import useDebounce from "../../hooks/useDebounce";
import { Ienums } from "../../utils/interface";

export default function Participants(props) {
  const navigate = useNavigate();
  // const { translate, idiom } = props
  const [search, setSearch] = useState("");
  const [participants, setParticipants] = useState([]);
  const [user, setUser] = useState();
  //paginação
  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [countData, setCountData] = useState(1);
  const [room, setRoom] = useState("");
  //confirmação
  const [participantSelected, setParticipantSelected] = useState();
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [success_dlgMe, setsuccess_dlgMe] = useState(false);

  const { t, idParticipant, idEvent, roomId, i18n } =
    useContext(ParticipantContext);

  const { matchConfig } = useContext(MatchContext);

  const debouncedSearch = useDebounce(search, 700);

  const dispatch = useDispatch();
  const { users, status } = useSelector((state) => state.user);

  async function selectRoommate(el) {
    if (users.some((e) => e.email === el.email)) {
      setsuccess_dlg(true);
      return;
    }
    await getRoommatesRoom(idEvent, idParticipant).then((res) => {
      if (res.message === Ienums.CHOOSEN) {
        setRoom(res.data.roomSize);
        setsuccess_dlgMe(true);
        return;
      } else {
        setUser(el);
        setconfirm_alert(true);
      }
    });
  }

  async function confirmSelection() {
    dispatch(
      createUser({
        id: user.id,
        name: user.name,
        email: user.email,
        type: Ienums.CHOOSEN,
        status: Ienums.PENDING_SEND,
        order: users.length + 1,
      })
    );
    setconfirm_alert(false);
    navigate({ pathname: "/match", search: window.location.search });
  }

  async function getAllParticipant() {
    if (search.length > 2 || search.length == 0)
      await getParticipants({
        eventId: idEvent,
        loginId: idParticipant,
        offset: currentIndex,
        limit: itemsPerPage,
        search: debouncedSearch,
      })
        .then((res) => {
          setParticipants(res.data);
          setCountData(res.count);
        })
        .catch((error) => console.log("function error", error));
  }

  useEffect(() => {
    if (idEvent && idParticipant) {
      getAllParticipant();
    }
  }, [idEvent, idParticipant, currentIndex, itemsPerPage, user]);

  useEffect(() => {
    if (idEvent && idParticipant) {
      getAllParticipant();
    }
  }, [debouncedSearch]);
  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0 0 0",
      }}
    >
      {confirm_alert ? (
        <SweetAlert
          title={`${t("Deseja selecionar")} ${user.name} ${t(
            "como parceiro(a) de quarto?"
          )}`}
          warning
          showCancel
          // botões de confirmar e cancelar trocados para alterar a ordem de exibição!!!!!!!!
          confirmBtnText={t("Não")}
          cancelBtnText={t("Sim")}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          onConfirm={() => {
            setconfirm_alert(false);
          }}
          onCancel={() => confirmSelection()}
        >
          {t(
            "Aviso: Essa ação não poderá ser anulada a menos que o(a) parceiro(a) escolhido(a) decline o convite."
          )}
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          danger
          title={"Esse participante já foi escolhido"}
          onConfirm={() => {
            // getAllParticipant();
            setsuccess_dlg(false);
          }}
        ></SweetAlert>
      ) : null}
      {success_dlgMe ? (
        <SweetAlert
          danger
          title={"Você recebeu uma solicitação de hospedagem!"}
          onConfirm={() => {
            setsuccess_dlgMe(false);
            dispatch(reset([]));
            navigate({
              pathname: "/match",
              search: `?idEvent=${idEvent}&idPart=${idParticipant}&room=${room}&idiom=${i18n.language}`,
            });
            //window.location.href = '/match' + `?idEvent=${idEvent}&idPart=${idParticipant}&room=${room}&idiom=${i18n.language}`
          }}
        ></SweetAlert>
      ) : null}
      <Col md={10} style={{ margin: 0, padding: 0 }}>
        <Card>
          <CardBody>
            <CardTitle
            // onClick={() => console.log(user.name.replace(/%20/g, " "))}
            >
              {t("ESCOLHA SEU PARCEIRO(A) DE QUARTO")}
            </CardTitle>
            <p>
              {t(
                "Caso não encontre seu parceiro(a) de quarto ele(a) pode ter sido indicado(a) por alguém e não irá mais aparecer na relação para escolha, verifique e retorne para fazer sua seleção."
              )}
            </p>
            <Col md={5}>
              <div className="app-search d-lg-block events-input-search">
                <label className="form-label">
                  {/* Escolha seu colega de quarto */}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder={t("Pesquise o(a) convidado(a)")}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </Col>
            <div className="table-responsive">
              {!isMobile && !window.ReactNativeWebView && participants ? (
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>{t("Convidados")}</th>
                      <th>Email</th>
                      <th>{t("Opção")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants?.length > 0 &&
                      participants
                        // ?.filter(
                        //     (e) =>
                        //       e.name
                        //       ?.toLocaleLowerCase()
                        //       ?.indexOf(search.toLocaleLowerCase()) > -1
                        //   )
                        // ?.filter((e) => e.id !== parseInt(idParticipant))
                        ?.map((e, i) => (
                          <tr key={i}>
                            <td>{e.name}</td>
                            <td>{e.email}</td>
                            <td>
                              <Button
                                color="success"
                                className="btn-select btn btn-success waves-effect waves-light"
                                style={{
                                  backgroundColor: matchConfig?.colorButton,
                                }}
                                onClick={() => {
                                  selectRoommate(e);
                                }}
                              >
                                {t("Convidar")}
                              </Button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              ) : (
                <Table className="table mb-0 table-sm table-striped">
                  <thead className="table-light">
                    <tr>
                      <th>{"Opção"}</th>
                      <th>{"Convidados"}</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants.length > 0 &&
                      participants
                        .filter(
                          (e) =>
                            e.name
                              .toLocaleLowerCase()
                              .indexOf(search.toLocaleLowerCase()) > -1
                        )
                        .filter((e) => e.id != idParticipant)
                        .map((e, i) => (
                          <tr key={i}>
                            <td>
                              <Button
                                color="success"
                                className="btn btn-success waves-effect waves-light"
                                onClick={() => {
                                  selectRoommate(e);
                                  // verifyParticipant(e);
                                }}
                                style={{ fontSize: "10px" }}
                              >
                                {t("Convidar")}
                              </Button>
                            </td>
                            <td
                              style={{ fontSize: "10px", maxWidth: "20vw" }}
                              onClick={() => ""}
                            >
                              {e.name}
                            </td>
                            <td style={{ fontSize: "10px", maxWidth: "20vw" }}>
                              {e.email}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              )}
            </div>
          </CardBody>
        </Card>

        <Pagination
          data={participants}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          countData={countData}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          maxPageButtonsToShow={3}
        />
      </Col>
    </Row>
  );
}
